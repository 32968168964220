<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/goods-list"
      api-download-url="/goods-list-export"
      download-file-name="hang-hoa.xlsx"
      @showUpdateImage="showUpdateImage"
      @showEMarketSQ="showEMarketSQ"
      @showDialogReservedQuantity="showDialogReservedQuantity"
      @showDialogCreateReserved="showDialogCreateReserved"
    ></custom-table>

    <v-dialog v-model="dialogEMarketSQ" persistent max-width="100vw">
      <EMarketSkuQuantity v-if="dialogEMarketSQ" @cancel="hideEMarketSQ" />
    </v-dialog>

    <v-dialog v-model="dialogUpdateImage" persistent max-width="350px">
      <UpdateImage
        v-if="dialogUpdateImage"
        :item="updatingGoods"
        @cancel="hideUpdateImage"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog v-model="dialogCreateReserved" persistent max-width="360px">
      <CreateReserved
        v-if="dialogCreateReserved"
        :item="updatingGoods"
        @cancel="hideDialogCreateReserved"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog v-model="dialogReservedQuantity" persistent max-width="100vw">
      <ReservedQuantity
        v-if="dialogReservedQuantity"
        :item="updatingGoods"
        @cancel="hideDialogReservedQuantity"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  IMAGE_OPTIONS,
  GOODS_STATUS_OPTIONS,
  CUSTOM_VIEW_WAREHOUSE_GOODS,
} from "@/libs/const";

export default {
  name: "Index",
  components: {
    UpdateImage: () => import("@/components/goods/UpdateImage"),
    EMarketSkuQuantity: () => import("@/components/goods/EMarketSkuQuantity"),
    ReservedQuantity: () => import("@/components/goods/ReservedQuantity"),
    CreateReserved: () => import("@/components/goods/CreateReserved"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_WAREHOUSE_GOODS,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
    statusOptions: [...GOODS_STATUS_OPTIONS],
    dialogEMarketSQ: false,
    dialogUpdateImage: false,
    dialogReservedQuantity: false,
    dialogCreateReserved: false,
    updatingGoods: {},
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const columns = [
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse"),
        placeholder: this.$t("labels.warehouse"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: false,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
        defaultValue: this.getUrlParameter("customer_goods_barcode")
          ? this.getUrlParameter("customer_goods_barcode")
          : "",
        buttonActions: [
          {
            action: "showDialogCreateReserved",
            text: this.$t("labels.reserved_goods"),
            color: "purple",
            class: "white--text",
            permissions: ["goods_reserved"],
          },
        ],
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        required: true,
        options: this.statusOptions,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: false,
        sortName: "sku",
        key: "sku",
        required: true,
        defaultValue: this.getUrlParameter("sku")
          ? this.getUrlParameter("sku")
          : "",
      },
      {
        type: "select-category",
        label: this.$t("labels.category"),
        placeholder: this.$t("labels.category"),
        name: "id_category",
        hasSort: false,
        sortName: "category_name",
        key: "category_name",
      },
      {
        type: "select-filter",
        label: this.$t("labels.image"),
        placeholder: this.$t("labels.image"),
        name: "image",
        hasSort: false,
        sortName: "url_images",
        key: "url_images",
        options: this.imageOptions,
        required: true,
        action: "showUpdateImage",
        actionText: this.$t("labels.update"),
        actionPermissions: [],
        component: "Image",
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "description",
        hasSort: false,
        sortName: "description",
        key: "description",
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: false,
        sortName: "size",
        key: "size",
      },
      {
        type: "input-filter",
        label: this.$t("labels.unit"),
        placeholder: this.$t("labels.unit"),
        name: "unit",
        hasSort: false,
        sortName: "unit",
        key: "unit",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.dxrxc"),
        placeholder: this.$t("labels.dxrxc"),
        name: "dxrxc",
        hasSort: false,
        sortName: "dxrxc",
        key: "dxrxc",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.weight"),
        placeholder: this.$t("labels.weight"),
        name: "weight",
        hasSort: false,
        sortName: "weight",
        key: "weight",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.price"),
        placeholder: this.$t("labels.price"),
        name: "price",
        hasSort: false,
        sortName: "price",
        key: "price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.wholesale_price"),
        placeholder: this.$t("labels.wholesale_price"),
        name: "wholesale_price",
        hasSort: false,
        sortName: "wholesale_price",
        key: "wholesale_price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receiving_quantity"),
        placeholder: this.$t("labels.receiving_quantity"),
        name: "receiving",
        hasSort: true,
        sortName: "receiving",
        key: "receiving",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.stowing_quantity"),
        placeholder: this.$t("labels.stowing_quantity"),
        name: "stowing",
        hasSort: true,
        sortName: "stowing",
        key: "stowing",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.wait_pickup_quantity"),
        placeholder: this.$t("labels.wait_pickup_quantity"),
        name: "wait_pickup",
        hasSort: true,
        sortName: "wait_pickup",
        key: "wait_pickup",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.pickedup_quantity"),
        placeholder: this.$t("labels.pickedup_quantity"),
        name: "pickedup",
        hasSort: true,
        sortName: "pickedup",
        key: "pickedup",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.available_quantity_1"),
        placeholder: this.$t("labels.available_quantity_1"),
        name: "available",
        hasSort: true,
        sortName: "available",
        key: "available",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.reserved_quantity"),
        placeholder: this.$t("labels.reserved_quantity"),
        name: "reserved",
        hasSort: true,
        sortName: "reserved",
        key: "reserved",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.return_quantity"),
        placeholder: this.$t("labels.return_quantity"),
        name: "returning",
        hasSort: true,
        sortName: "returning",
        key: "returning",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [
      {
        text: this.$t("labels.e_market_sync"),
        action: "showEMarketSQ",
        link: "",
        permissions: ["goods_syn_e_market"],
        color: "success",
        class: "mr-2",
      },
      {
        text: this.$t("labels.combo"),
        action: "",
        link: "/goods/combo",
        permissions: [],
        color: "",
        class:
          "mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default warning",
      },
      {
        text: this.$t("labels.reserved_goods"),
        action: "showDialogReservedQuantity",
        link: "",
        permissions: ["goods_reserved"],
        color: "purple",
        class: "white--text",
      },
    ];
    this.footerActions = [...footerActions];

    const sums = [
      {
        text: this.$t("labels.sku"),
        key: "sumSku",
      },
      {
        text: this.$t("labels.avai_sku"),
        key: "sumAvaiSku",
      },
      {
        text: this.$t("labels.available_quantity_1"),
        key: "sumAvailable",
      },
      {
        text: this.$t("labels.receiving_quantity"),
        key: "sumReceiving",
      },
      {
        text: this.$t("labels.export_quantity"),
        key: "sumTotalPick",
      },
      {
        text: this.$t("labels.reserved_quantity"),
        key: "sumReserved",
      },
      {
        text: this.$t("labels.return_quantity"),
        key: "sumReturning",
      },
    ];
    this.sums = [...sums];
  },
  mounted() {
    // this.getList()
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },

    showUpdateImage(goods) {
      this.updatingGoods = { ...goods };
      this.dialogUpdateImage = true;
    },
    hideUpdateImage() {
      this.updatingGoods = {};
      this.dialogUpdateImage = false;
    },
    showDialogReservedQuantity(goods) {
      this.updatingGoods = { ...goods };
      this.dialogReservedQuantity = true;
    },
    hideDialogReservedQuantity() {
      this.updatingGoods = {};
      this.dialogReservedQuantity = false;
    },
    showDialogCreateReserved(goods) {
      this.updatingGoods = { ...goods };
      this.dialogCreateReserved = true;
    },
    hideDialogCreateReserved() {
      this.updatingGoods = {};
      this.dialogCreateReserved = false;
    },
    showEMarketSQ() {
      this.dialogEMarketSQ = true;
    },
    hideEMarketSQ() {
      this.dialogEMarketSQ = false;
    },
  },
};
</script>

<style scoped></style>
